import axios from './index'

export const GetCategories = params => {
  return axios.request({
    url: '/exam/categories',
    method: 'get',
    params
  })
}

export const GetArticles = params => {
  return axios.request({
    url: '/exam/articles',
    method: 'get',
    params
  })
}

export const GetDetail = params => {
  return axios.request({
    url: '/exam/detail',
    method: 'get',
    params
  })
}
