<template>
  <div class="com-father">
    <div class="home-cntent">

      <!--      头部标题-->
      <Hiders></Hiders>
      <!--      图表区域-->
      <div class="community-cons">
        <div class="gohome"><span class="iconfont icon-fangzi01-copy iconfontbac"></span><a href="/">返回首页</a></div>
        <div class="com-left">
          <div v-for="item in dadedat" :key="item.id" :class="[{'com-Select':item.type},'left-item','shou']"
               @click="gotodetails(item.id)">
            {{ item.name }}
          </div>

        </div>
        <div class="com-right"
             v-loading="loading"
             element-loading-text="努力加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.5)">
                    <div v-if="!detaitype" class="right-list">
                    <div @click="gototext(item.id)" class="right-item shou" v-for="item in textData" :key="item.id">
                      <span class="yuan">●</span>
                      {{item.title}}
                      <span style="float: right;">{{item.create_time}}</span>
                    </div>
                    </div>

          <div v-if="detaitype" class="detailedtext">
            <div v-cloak class="detai-title">{{detailedtextdata.title}}</div>
            <div v-cloak v-html="detailedtextdata.content" class="detai-com">

            </div>
          </div>
        </div>
      </div>

      <!--      图表区域结束-->
    </div>
  </div>
</template>

<script>
import Hiders from '@/components/Hiders'
import { GetCategories, GetArticles, GetDetail } from '@/api/ass'

export default {
  data () {
    return {
      loading: true,
      detaitype: false,
      dadedat: [],
      textData: [],
      articdata: {
        type_id: 1
      },
      detailedtextdata: {

      }

    }
  },
  components: {
    Hiders
  },
  mounted () {
    this.getcatego()
  },
  methods: {
    // 点击左侧按钮
    gotodetails (ids) {
      // 清空内容
      this.detailedtextdata = {}
      this.loading = true
      this.dadedat.forEach(item => {
        item.id === ids ? item.type = true : item.type = false
      })
      this.detaitype = false
      this.articdata.type_id = ids
      this.getarticles(this.articdata)
    },
    gototext (ids) { // 点击标题获取文章
      this.detaitype = true
      this.loading = true
      this.getdetail({ article_id: ids })
    },
    // 请求
    async getcatego () {
      const { data: { data } } = await GetCategories()
      this.dadedat = data
      console.log(data)

      this.articdata.type_id = this.dadedat[0].id
      this.getarticles(this.articdata)
    },
    // 获取文章列表
    async getarticles (id) {
      const { data } = await GetArticles(id)
      this.textData = data.data
      this.textData.forEach(item => {
        item.create_time = this.timestampToTime(item.create_time)
      })
      if (data.code === 200) {
        this.loading = false
      }
    },
    // 获取内容
    async getdetail (id) {
      const { data } = await GetDetail(id)
      this.detailedtextdata = data.data.info
      if (data.code === 200) {
        this.loading = false
      }
      console.log(data)
    },
    // 时间戳转换
    timestampToTime (time) {
      // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var date = new Date(time * 1000)
      const y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? ('0' + MM) : MM
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      let h = date.getHours()
      h = h < 10 ? ('0' + h) : h
      let m = date.getMinutes()
      m = m < 10 ? ('0' + m) : m
      let s = date.getSeconds()
      s = s < 10 ? ('0' + s) : s
      return y + '-' + MM + '-' + d
    }
  }

}
</script>
<style lang="less" scoped>
@import '~@/assets/css/index.less';

#app {

  .com-father {
    background: url("../assets/img/back2.jpg") no-repeat;
    background-size: 100% 100%;
    background-position: center center;

    .home-cntent {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      .m-map {
        position: absolute;
        z-index: 1;
      }

      .home-echers {
        width: 100%;
        height: 100%;

        position: relative;

        .echers-left, .echers-right {
          width: @show-whith;

          background: #00000073;
          position: absolute;
          top: 0;
          border-top: 3px solid #ffffff4d;
          z-index: 4;

        }

        &:before {
          content: '';
          width: 100%;
          position: absolute;
          bottom: 0;
          z-index: 2;
          box-shadow: 0px 37px 178px 30px #052957;
        }
      }
    }

    .community-cons {
      padding: 3% 3.2%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: flex-start;
      flex-wrap: wrap;
      overflow: auto;
      max-width: 1920px;
      margin: 0 auto;
      z-index: 3;

      .com-left {
        width: 412px;
        height: 702px;
        background: #00000050;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        padding-top: 40px;
        overflow: auto;
        margin-top: 40px;

        .left-item {
          width: 309px;
          height: 57px;
          background: #70707050;
          border: 1px solid #707070;
          opacity: 1;
          border-radius: 11px;
          font-size: 23px;
          color: white;
          text-align: center;
          line-height: 57px;
          margin-bottom: 30px;

          &:hover {
            background: #8A95A6;
          }
        }

      }

      .com-right {
        width: 1132-53-53px;
        height: 742-40-85px;
        margin-left: 27px;
        background: #00000050;
        padding: 40px 53px 85px 53px;
        margin-top: 40px;

        .right-list {
          .right-item {
            font-size: 16px;
            line-height: 40px;
            color: #FFFFFF;
            opacity: 1;
            border-bottom: 1px dashed #97A5AB;

            .yuan {
              font-size: 25px;
              margin-right: 10px;
            }
          }
        }

        .detailedtext {
          overflow: auto;
          .detai-title {
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 39px;
            color: #00FFFF;
            border-bottom: 3px solid #97A5AB;
            padding-bottom: 10px;
          }

          .detai-com {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;

            line-height: 33px;
            height: 600px;
            color: white;
            /deep/ img{
              max-width: 400px;
            }

            /deep/ a{
                color: #ed721d!important;
              }

          }
        }

      }

      .com-Select {
        background: #8A95A6 !important;
      }
    }
  }
}

.com-left::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.com-left::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

.com-left::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FFF;
  background-image: -webkit-linear-gradient(top, #e4f5fc 0%, #bfe8f9 50%, #9fd8ef 51%, #2ab0ed 100%);
}

.com-left::-webkit-scrollbar {
  width: 0 !important
}
.shou:hover{
  cursor:pointer
}
.detailedtext::-webkit-scrollbar-track{ -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1); background-color: #F5F5F5; border-radius: 10px;}
.detailedtext::-webkit-scrollbar{ width: 10px; background-color: transparent;}
.detailedtext::-webkit-scrollbar-thumb{ border-radius: 10px; background-color: #FFF; background-image: -webkit-linear-gradient(top, #e4f5fc 0%, #bfe8f9 50%, #9fd8ef 51%, #2ab0ed 100%);}
[v-cloak]{
  display: none;
}
</style>
